import { loadYandexMap } from '~/js/helpers';
import $$ from '~/js/common/plugins';

{
    const mapInfo = document.querySelector('.center-location__info');
    const mapInfoClose = mapInfo.querySelector('.center-location__exit');
    loadYandexMap('https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=e6cc9cae-9eef-43c1-9f2b-5cce67c4bb13').then(
        () => {
            ymaps.ready(init);
            function init() {
                const myMap = new ymaps.Map(
                    'map',
                    {
                        center: [55.765529, 37.639195],
                        zoom: 13,
                    },
                    {
                        searchControlProvider: 'yandex#search',
                    }
                );
                const myPlacemark = new ymaps.Placemark(
                    myMap.getCenter(),
                    {
                        // hintContent: 'ул. Мясницкая, д. 32, стр. 1, 3 этаж',
                        // balloonContent: 'ул. Мясницкая, д. 32, стр. 1, 3 этаж',
                    },
                    {
                        iconLayout: 'default#image',
                        iconImageHref: require('/assets/images/main-page/map-point.png'),
                        iconImageSize: [42, 33],
                        iconImageOffset: [-21, -16],
                        // hasBalloon: false,
                    }
                );

                myPlacemark.events.add('click', function () {
                    $$.fadeToggle(mapInfo);
                });
                myMap.geoObjects.add(myPlacemark);

                mapInfoClose.addEventListener('click', (e) => {
                    $$.fadeOut(mapInfo);
                });
            }
        }
    );
}
