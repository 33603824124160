const { default: $$ } = require('~/js/common/plugins');

{
    const isDesktop = window.matchMedia('(min-width: 1025px)').matches;

    if (isDesktop) {
        const mainHeader = document.querySelector('.main-header');
        const fixedHeader = document.querySelector('.main-header__fixed-row');

        const mainNav = document.querySelector('.main-header .main-nav');
        const fixedMenuNav = document.querySelector('.main-header__fixed-menu');

        const navCopy = mainNav.cloneNode(true);
        fixedMenuNav.append(navCopy);

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= 0.9) {
                        fixedHeader.classList.remove('fixed');
                    } else {
                        fixedHeader.classList.add('fixed');
                    }
                });
            },
            {
                rootMargin: '250px 0% 0% 0%',
                threshold: [0.9],
            }
        );

        observer.observe(mainHeader);
    } else {
        const mainNav = document.querySelector('.main-header .main-nav');
        const mobileNavWrapper = document.querySelector('.mobile-header__navigation');

        const navCopy = mainNav.cloneNode(true);
        mobileNavWrapper.append(navCopy);

        const mobileNav = document.querySelector('.mobile-header .main-nav');
        const innerMenuTriggers = mobileNav.querySelectorAll('.has-inner .main-nav__top-link');

        innerMenuTriggers.forEach((trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault();

                const parentLi = trigger.parentElement;
                const dropDownMenu = trigger.nextElementSibling;

                parentLi.classList.toggle('active');
                $$.slideToggle(dropDownMenu);
            });
        });
    }

    const searchTrigger = document.querySelector('.main-header__search-trigger');
    const search = document.querySelector('.main-header__search');
    const searchInput = search.querySelector('input');
    const searchClose = document.querySelector('.main-header__search-close');

    searchTrigger.addEventListener('click', (e) => {
        search.classList.add('active');
        searchInput.focus();
    });

    searchClose.addEventListener('click', (e) => {
        search.classList.remove('active');
    });

    const scrollToMap = document.querySelectorAll('.main-header__location-link');
    const mapContainer = document.querySelector('.center-location__map');

    scrollToMap.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            $$.scrollTo(mapContainer, 76);
        });
    });
}
