import tippy from 'tippy.js';

{
    const serviceAbout = document.querySelector('.service-about');
    if (serviceAbout) {
        const tooltipTriggers = serviceAbout.querySelectorAll('.service-about__tooltip-trigger');

        tooltipTriggers.forEach((btn) => {
            const tooltipTemplate = `<b class='tooltip-title'>${btn.dataset.title}</b><p class='tooltip-content'>${btn.dataset.content}</p>`;
            const tooltip = tippy(btn, {
                content: tooltipTemplate,
                allowHTML: true,
                maxWidth: 280,
                arrow: false,
                theme: 'mosplod',
            });
        });
    }
}
