
import Flickity from 'flickity';

{
    const elem4 = document.querySelector('.services__wrapper');
    if (elem4) {
        const flkty4 = new Flickity( elem4, {
            cellAlign: 'left',
            contain: true,
            arrowShape: '',
            watchCSS: true
        });
    };
}