const { default: $$ } = require('~/js/common/plugins');

(function () {
    const serviceBanner = document.querySelector('.service-banner');
    if (!serviceBanner) return;

    const priceSwitcher = document.querySelector('.service-banner__price-switcher');
    const prices = serviceBanner.querySelectorAll('.service-banner__price span');
    const switcher = priceSwitcher.querySelector('.switcher');

    priceSwitcher.addEventListener('click', (e) => {
        const priceSwitcherWidth = priceSwitcher.getBoundingClientRect().width;
        const target = e.target;
        const action = target.dataset.action;

        if (target.classList.contains('active')) return;
        [...priceSwitcher.children].forEach((btn) => btn.classList.remove('active'));

        priceSwitcher.classList.add('disabled');
        const targetWidth = target.offsetWidth;
        target.classList.add('active');
        switcher.style.width = targetWidth + 'px';

        const activePrice = [...prices].find((price) => price.classList.contains('active'));
        const relatedPrice = [...prices].find((price) => price.dataset.type === action);

        if (action === 'price1') {
            switcher.style.left = '0';
        } else {
            switcher.style.left = priceSwitcherWidth - targetWidth + 'px';
        }

        $$.fadeOut(activePrice, { speed: 200 }).then(() => {
            activePrice.classList.remove('active');
            relatedPrice.classList.add('active');
            $$.fadeIn(relatedPrice, { speed: 200 }).then(() => {
                priceSwitcher.classList.remove('disabled');
            });
        });
    });

    const anchors = serviceBanner.querySelectorAll('.service-banner__anchor');
    anchors.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            const hash = link.hash;

            const target = document.querySelector(hash);
            $$.scrollTo(target, 100);
        });
    });
})();
