const { default: $$ } = require('~/js/common/plugins');

(function () {
    // const fixedMobileHeader = document.querySelector('.mobile-header__fixed');
    // const mobileHeader = document.querySelector('.mobile-header');
    // if ('IntersectionObserver' in window) {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                 if (entry.intersectionRatio >= 0.9) {
    //                     fixedMobileHeader.classList.remove('fixed');
    //                 } else {
    //                     fixedMobileHeader.classList.add('fixed');
    //                 }
    //             });
    //         },
    //         {
    //             rootMargin: '250px 0% 0% 0%',
    //             threshold: [0.9],
    //         }
    //     );

    //     observer.observe(mobileHeader);
    // }

    const scrollToMap = document.querySelectorAll('.mobile-header__location-link');
    const mapContainer = document.querySelector('.center-location__map');
    const mobileMenu = document.querySelector('.mobile-header__menu');
    const hamburgers = document.querySelectorAll('.hamburger');
    scrollToMap.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            mobileMenu.classList.remove('active');
            document.body.classList.remove('menu-opened');
            hamburgers.forEach((hamburger) => {
                hamburger.classList.remove('active');
            });
            $$.scrollTo(mapContainer, 160);
        });
    });
})();
