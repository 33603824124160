import MicroModal from 'micromodal';
import Inputmask from 'inputmask';

Inputmask.extendDefinitions({
    f: {
        //masksymbol
        validator: '[0-7|9]',
    },
});
const phoneMask = '+7 (f99) 999-99-99';

const inputPhones = document.querySelectorAll('[data-type="phone"]');
inputPhones.forEach((phone) => {
    const phoneInputMask = new Inputmask({
        mask: phoneMask,
        showMaskOnHover: false,
    });
    phoneInputMask.mask(phone);
});

{
    MicroModal.init();

    const modal1Phone = document.querySelector('#modal1Phone');
    const phoneInputMask = new Inputmask({
        mask: phoneMask,
        showMaskOnHover: false,
    });
    phoneInputMask.mask(modal1Phone);

    const headerButtons = document.querySelectorAll('.main-header__order');
    if (headerButtons.length > 0) {
        headerButtons.forEach((headerButton) => {
            headerButton.addEventListener('click', (e) => {
                e.preventDefault();
                MicroModal.show('modal-1'); // [1]
            });
        });
    }

    const fixedHeaderButton = document.querySelector('.main-header__fixed-order');
    if (fixedHeaderButton) {
        fixedHeaderButton.addEventListener('click', (e) => {
            e.preventDefault();
            MicroModal.show('modal-1'); // [1]
        });
    }

    const mobileHeaderOrder = document.querySelectorAll('.mobile-header__order');
    if (mobileHeaderOrder.length > 0) {
        mobileHeaderOrder.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                MicroModal.show('modal-1'); // [1]
            });
        });
    }

    const fixedMobileHeaderOrder = document.querySelector('.mobile-header__order');
    if (fixedMobileHeaderOrder) {
        fixedMobileHeaderOrder.addEventListener('click', (e) => {
            e.preventDefault();
            MicroModal.show('modal-1'); // [1]
        });
    }

    const callButton = document.querySelector('.make-call__wrapper button');
    if (callButton) {
        callButton.addEventListener('click', (e) => {
            // e.preventDefault();
            // MicroModal.show('modal-2'); // [1]
        });
    }

    const sliderButton = document.querySelectorAll('.slider .carousel-cell button');
    if (sliderButton) {
        sliderButton.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                MicroModal.show('modal-1'); // [1]
            });
        });
    }

    const footerBtn = document.querySelector('.main-footer__mail');
    if (footerBtn) {
        footerBtn.addEventListener('click', (e) => {
            e.preventDefault();
            MicroModal.show('modal-3'); // [1]
        });
    }

    const loc = document.querySelector('.center-location__order');
    if (loc) {
        loc.addEventListener('click', (e) => {
            e.preventDefault();
            MicroModal.show('modal-10'); // [1]
        });
    }

    const consult = document.querySelector('.main-header__consult');
    if (consult) {
        consult.addEventListener('click', (e) => {
            e.preventDefault();
            MicroModal.show('modal-4'); // [1]
        });
    }
}
