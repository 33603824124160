import Flickity from 'flickity';
{
    var elem3 = document.querySelector('.comments__slider');
    if (elem3) {
        var flkty3 = new Flickity( elem3, {
          cellAlign: 'left',
          arrowShape: '',
          groupCells: true,
          contain: true,
          adaptiveHeight: true
        });
    }


    const commentSlides = document.querySelectorAll('.comments__slider .carousel-cell');
    commentSlides.forEach((slide) => {
        const content = slide.querySelector('p');
        const moreLink = slide.querySelector('.comments__more');

        content.classList.add('hide');

        if (content) {
            const contentHeight = content.scrollHeight;

            if (contentHeight <= 168) {
                content.classList.remove('hide');
                moreLink.style.display = 'none';
            }

            moreLink.addEventListener('click', (e) => {
                e.preventDefault();
                content.classList.remove('hide');
                content.style.maxHeight = 'none';
                moreLink.style.display = 'none';

                slide.style.height = 'auto';
                flkty3.resize();
            });
        }
    });
}

