(function () {
    const mobileHeader = document.querySelector('.mobile-header');
    const hamburgers = document.querySelectorAll('.hamburger');
    const mobileMenu = document.querySelector('.mobile-header__menu');

    hamburgers.forEach((hamburger) => {
        hamburger.addEventListener('click', (e) => {
            e.preventDefault();

            hamburger.classList.toggle('active');
            mobileMenu.classList.toggle('active');
            document.body.classList.toggle('menu-opened');
        });
    });

    const menuClose = document.querySelector('.mobile-header__menu-close');
    menuClose.addEventListener('click', (e) => {
        e.preventDefault();

        hamburgers.forEach((hamburger) => {
            hamburger.classList.remove('active');
        });

        mobileMenu.classList.remove('active');
        document.body.classList.remove('menu-opened');
    });

})();
