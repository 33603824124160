(function () {
    const scrollTopOuter = document.querySelector('.scroll-top');
    const scrollTopButton = document.querySelector('.scroll-top__button');

    if (!scrollTopOuter) return;

    if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio < 0.1) {
                    scrollTopButton.classList.add('visible');
                } else {
                    scrollTopButton.classList.remove('visible');
                }
            });
        }, {threshold: [0, 0.2]});
        observer.observe(scrollTopOuter);
    } else {
        scrollTopButton.classList.add('visible');
    }

    scrollTopButton.addEventListener('click', (e) => {
        e.preventDefault();

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });
})();
