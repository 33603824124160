import Flickity from 'flickity';
import imagesLoaded from 'flickity-imagesloaded';

(function() {
    const blockquotesSlider = document.querySelector('.blockquotes-slider');
    if (!blockquotesSlider) return;

    const sliderNode = blockquotesSlider.querySelector('.blockquotes-slider__slides')

    const slider = new Flickity(sliderNode, {
        contain: true,
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false,
        imagesLoaded: true,
    })

    slider.on('change', (index) => {
        console.log(slider, index);
    })
})();