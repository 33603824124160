import Flickity from 'flickity';

{
    const newsSlider = document.querySelector('.news__right');
    if (newsSlider) {
        const slider = new Flickity(newsSlider, {
            cellAlign: 'left',
            contain: true,
            arrowShape: '',
            watchCSS: true,
            pageDots: false
        });
    }
}
