//libs
import Flickity from 'flickity';
import imagesloaded from 'flickity-imagesloaded';
import WOW from 'wow.js';

//common
// import $$ from './common/plugins';

//blocks
import '../blocks/comments/comments';
import '../blocks/center-location/center-location';
import '../blocks/modals/modals';
import '../blocks/service-banner/service-banner';
import '../blocks/service-about/service-about';
import '../blocks/services/services';
import '../blocks/main-header/main-header';
import '../blocks/mobile-header/mobile-header';
import '../blocks/news/news';
import '../blocks/workers/workers';
import '../blocks/sales-slider/sales-slider';
import '../blocks/hamburger/hamburger';
import '../blocks/blockquotes-slider/blockquotes-slider';
import '../blocks/scroll-top/scroll-top';

new WOW({ mobile: false }).init();

const elem1 = document.querySelector('.slider');
if (elem1) {
    const flkty1 = new Flickity(elem1, {
        cellAlign: 'left',
        contain: true,
        arrowShape: '',
    });
}

const questionButton = document.querySelector('.question__form .ask');
if (questionButton) {
    questionButton.addEventListener('click', (e) => {
        e.preventDefault();
        MicroModal.show('modal-3'); // [1]
    });
}