import Flickity from 'flickity';
import imagesloaded from 'flickity-imagesloaded';

(function () {
    const salesSlider = document.querySelector('.sales-slider');
    if (!salesSlider) return;

    const sliderNode = salesSlider.querySelector('.sales-slider__slider');
    const slides = document.querySelectorAll('.sales-slider__slide');

    if (slides.length === 0) return;

    const sliderWidth = sliderNode.offsetWidth;
    const style = window.getComputedStyle(slides[0]);
    const slideMargin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    const allSlidesWidth = [...slides].reduce((acc, slide) => {
        acc = acc + slide.offsetWidth + slideMargin;
        return acc;
    }, 0);
    if (allSlidesWidth - slideMargin <= sliderWidth) {
        sliderNode.classList.add('flex');
    } else {
        const flkty = new Flickity(sliderNode, {
            cellAlign: 'left',
            arrowShape: '',
            imagesLoaded: true,
            contain: true,
            pageDots: false,
            wrapAround: true,
        });
    }
})();
