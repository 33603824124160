import Flickity from 'flickity';
import imagesloaded from 'flickity-imagesloaded';

const slider = document.querySelector('.workers__slider');
const slides = document.querySelectorAll('.workers__slider .carousel-cell');
if (slider) {
    const sliderWidth = slider.offsetWidth;
    const style = window.getComputedStyle(slides[0]);
    const slideMargin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    const allSlidesWidth = [...slides].reduce((acc, slide) => {
        acc = acc + slide.offsetWidth + slideMargin;
        return acc;
    }, 0);
    if (allSlidesWidth - slideMargin <= sliderWidth) {
        slider.classList.add('flex')
    } else {
        const flkty = new Flickity(slider, {
            cellAlign: 'left',
            imagesLoaded: true,
            // groupCells: true,
            wrapAround: true,
            arrowShape: '',
            contain: true,
        });
    }
}
